import React from "react"
import { graphql } from "gatsby"
import * as styles from './services.module.css'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'

class ServicesPage extends React.Component {
  render() {
    const post = get(this.props, 'data.allContentfulServices.edges[0].node')
    console.log(JSON.stringify(post));
    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={post.description.childMarkdownRemark.excerpt}
          image={`http:${post.heroImage.resize.src}`}
        />
        <Hero
          image={post.heroImage.gatsbyImageData}
          title={post.title}
          content={post.description.childMarkdownRemark.excerpt}
        />
        <div className={styles.container}>
          <div className={styles.article}>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allContentfulServices(limit: 1, filter: {slug: {eq: "services"}}) {
      edges {
        node {
          title
          heroImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
            resize(width: 630, height: 1280) {
              src
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              excerpt
            }
          }
        }
      }
    }
  }
`

export default ServicesPage
